import React from 'react';
import cls from 'classnames';
import { useSelector } from 'react-redux';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  selectCanApproveMembers,
  selectGroup,
  selectMembersWithCountLabel,
} from 'store/selectors';
import { UISref } from '@wix/tpa-router/react';

import { Box } from 'wui/Box';
import { Show } from 'wui/Show';
import { Stack } from 'wui/Stack';
import { TextButton } from 'wui/TextButton';
import { Typography, ITypographyProps } from 'wui/Typography';

import { GroupPrivacyLabel } from '../GroupPrivacyLabel';

import classes from './GroupInfo.scss';

interface IGroupInfoProps
  extends Omit<React.ComponentProps<typeof Stack>, 'children'> {
  wired?: boolean;
  groupId: string;
  misc?: boolean;
  variant?: ITypographyProps['variant'];
  noPendingMembers?: boolean;
}

export function GroupInfo({
  groupId,
  misc,
  wired,
  variant,
  noPendingMembers,
  'data-hook': dataHook,
  ...rest
}: IGroupInfoProps) {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const group = useSelector(selectGroup(groupId));
  const memberLabel = useSelector(selectMembersWithCountLabel(groupId));
  const canApproveMembers = useSelector(selectCanApproveMembers(groupId));

  const pendingMembersCount = group.pendingMembersCount;

  if (pendingMembersCount && canApproveMembers && misc) {
    return (
      <UISref
        state="group.members"
        params={{ slug: group.slug, expandJoinedRequests: true }}
      >
        <TextButton data-hook={dataHook} as="a">
          <span data-hook="pending-members-count">
            {t('groups-web.group-list.pending-members.count_icu', {
              count: pendingMembersCount,
            })}
          </span>
        </TextButton>
      </UISref>
    );
  }

  return (
    <Typography
      as="div"
      variant={variant}
      secondary={!wired}
      data-hook={dataHook}
      className={cls(classes.root, {
        [classes.wired]: wired,
        [classes.mobile]: isMobile,
      })}
    >
      <Stack truncate {...rest}>
        <GroupPrivacyLabel
          group={group}
          data-hook="group-privacy"
          className={classes.privacyLabel}
        />

        <span className={cls(classes.separator, classes.memberCount)}>
          &middot;
        </span>

        <span data-hook="members-count" className={classes.memberCount}>
          {memberLabel.isCustom
            ? `${group.membersCount ?? 0} ${memberLabel.label}`
            : t(memberLabel.label, {
                formattedCount: group.membersCount ?? 0,
              })}
        </span>

        <Show
          if={
            !noPendingMembers &&
            !!(group.pendingMembersCount && canApproveMembers)
          }
        >
          <>
            <span className={classes.separator}>&middot;</span>

            <UISref
              state="group.members"
              params={{ slug: group.slug, expandJoinedRequests: true }}
            >
              <TextButton as="a" data-hook="pending-members-count">
                <span data-hook="pending-members-count">
                  {t('groups-web.group-list.pending-members.count_icu', {
                    count: pendingMembersCount ?? 0,
                  })}
                </span>
              </TextButton>
            </UISref>
          </>
        </Show>
      </Stack>
    </Typography>
  );
}

GroupInfo.displayName = 'GroupInfo';
